import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import homeEN from "./locale/en/home.json";
import homeAR from "./locale/ar/home.json";

import contactEN from "./locale/en/contact.json";
import contactAR from "./locale/ar/contact.json";

import aboutEN from "./locale/en/about.json";
import aboutAR from "./locale/ar/about.json";

import galleryEN from "./locale/en/gallery.json";
import galleryAR from "./locale/ar/gallery.json";

const resources = {
  en: {
    home: homeEN,
    contact: contactEN,
    about: aboutEN,
    gallery: galleryEN,
  },
  ar: {
    home: homeAR,
    about: aboutAR,
    contact: contactAR,
    gallery: galleryAR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
