import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./core/routes/AppRoutes";
import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material";

const Home = lazy(() => import("./modules/home/presentation/Home"));
const AboutUs = lazy(() => import("./modules/about/presentation/AboutUs"));
const ContactUs = lazy(
  () => import("./modules/contact/presentation/ContactUs")
);
const Gallery = lazy(() => import("./modules/gallery/presentation/Gallery"));

const App = () => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const { language } = i18n;
  const theme = createTheme({
    typography: {
      fontFamily: language === "ar" ? "'El Messiri', sans-serif;" : "",
      h1: {
        fontFamily: "'Lora', serif;",
      },
      h2: {
        fontFamily: "'Lora', serif;",
      },
      h3: {
        fontFamily: "'Lora', serif;",
      },
      h4: {
        fontFamily: "'Lora', serif;",
      },
      h5: {
        fontFamily: "'Lora', serif;",
      },
      h6: {
        fontFamily: "'Lora', serif;",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Suspense >
        <Router>
          <Routes>
            <Route path={AppRoutes.home} element={<Home />} />
            <Route path={AppRoutes.aboutus} element={<AboutUs />} />
            <Route path={AppRoutes.contactus} element={<ContactUs />} />
            <Route path={AppRoutes.gallery} element={<Gallery />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
